import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import SimpleNavbar from '../../../components/navbar/SimpleNavbar';
import HeroReferral from '../../../components/hero/HeroReferral';
import SimpleFooter from '../../../components/footer/SimpleFooter';
import { CUSTOM_EVENT, pushEvent } from '../../../configs/clevertap';
import GTM_TRACKERS from '../../../../constants/gtm-trackers';
import SEO from '../../../SEO';

export default function Register() {
  const [formValue, setFormValue] = React.useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/api-v2-customer/referral/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referrerName: formValue.name,
        referrerEmail: formValue.email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setFormValue({
            name: '',
            email: '',
          });
          navigate('/referral/register/thank-you');
        }

        return response.json();
      })
      .then((data) => {
        if (data.message === 'Success') return;

        // eslint-disable-next-line no-alert
        throw new Error(data.message);
      })
      .catch((err) => {
        // eslint-disable-next-line no-alert
        window.alert(err.message || 'Terjadi kesalahan, silakan coba beberapa saat lagi.');
      });
  };

  return (
    <>
      <SEO
        title="DepositoBPR Referral Program | Registration"
        metaDescription="DepositoBPR Referral Program #AjakTemanDapatBonus Registration"
      />
      <div className="flex flex-col items-center">
        <div className="w-full bg-blue-light40">
          <SimpleNavbar />
          <main className="flex flex-col gap-y-4 my-4 relative">
            <div className="hidden md:block absolute left-0 top-72 h-full">
              <div className="w-full sticky top-0 z-0">
                <StaticImage
                  src="../../../images/referral/big-vector.svg"
                  alt="big-vector"
                />
              </div>
            </div>
            <div className="hidden md:block absolute left-0 h-full">
              <div className="sticky top-0 z-0">
                <StaticImage
                  src="../../../images/referral/coins.webp"
                  alt="coins"
                  className="w-screen"
                />
              </div>
            </div>
            <article className="md:hidden relative flex flex-col items-center gap-y-4 py-6">
              <HeroReferral />
            </article>
            <article className="hidden relative md:flex justify-between md:gap-y-4 md:py-6 lg:mx-64 md:mx-20">
              <HeroReferral />
              <div />
              <StaticImage
                src="../../../images/referral/register/masbee-missbee.webp"
                alt="masbee-missbee"
                objectFit="contain"
                className="w-[560px] absolute -bottom-6 -right-28 z-0"
              />
            </article>
            <div className="flex flex-col items-center mx-4 lg:mx-64 md:mx-20">
              <div className="md:hidden block">
                <StaticImage
                  src="../../../images/referral/register/masbee-missbee.webp"
                  alt="masbee-missbee"
                  objectFit="contain"
                  className="w-72 z-0"
                />
              </div>
              <article className="bg-white rounded-lg p-5 -mt-2 z-10 flex flex-col md:px-36">
                <h3 className="text-lg text-blue-light40 font-medium text-center my-8">
                  Mulai Ajak Teman Deposito & Nikmati <span className="font-bold">BONUS JUTAAN</span> Rupiah
                </h3>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-y-6"
                >
                  <label className="flex flex-col gap-y-2 text-sm font-medium">
                    Nama
                    <input
                      className="border-[1px] p-3 border-gray rounded-lg text-sm"
                      type="text"
                      placeholder="Masukkan nama di sini"
                      value={formValue.name}
                      name="name"
                      onChange={(e) => handleChange(e)}
                    />
                  </label>
                  <label className="flex flex-col gap-y-2 text-sm font-medium">
                    Email
                    <input
                      className="border-[1px] p-3 border-gray rounded-lg text-sm"
                      type="text"
                      placeholder="Masukkan email di sini"
                      value={formValue.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                    />
                  </label>
                  <div className={`flex justify-center ${GTM_TRACKERS.REFERRAL.SUBMIT_REGISTER}`}>
                    <button
                      onClick={() => pushEvent(CUSTOM_EVENT.GABUNG_REFERRAL, {
                        name: formValue.name,
                        email: formValue.email,
                      })}
                      type="submit"
                      // eslint-disable-next-line max-len
                      className="w-full md:w-60 text-sm text-blue-light40 font-semibold text-center py-4 bg-gradient-to-b from-yellow-400 to-yellow-500 shadow-[0_6px_10px_0_rgba(0,0,0,0.25)] rounded-xl border-[1px] border-secondary-100 disabled:opacity-30 disabled:cursor-not-allowed"
                      disabled={!formValue.name || !formValue.email}
                    >
                      Daftar Sekarang
                    </button>
                  </div>
                </form>
              </article>
            </div>
          </main>
          <SimpleFooter />
        </div>
      </div>
    </>
  );
}
