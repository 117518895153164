import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function HeroReferral() {
  return (
    <div className="z-20 md:grid md:grid-rows-2">
      <div className="hidden md:block md:h-full" />
      <div className="md:absolute md:-left-6">
        <StaticImage
          src="../../images/referral/ajak-teman.webp"
          alt="ajak-teman"
          objectFit="contain"
          className="w-64 z-30"
        />
      </div>
      <div className="absolute top-5 md:-left-6 z-20">
        <StaticImage
          src="../../images/referral/clouds.webp"
          alt="clouds"
          objectFit="contain"
          className="w-64"
        />
      </div>
      <div className="flex flex-col items-center md:items-start z-30 md:pb-4 md:pt-8 lg:pt-6">
        <h3 className="font-normal text-xl text-white text-center lg:text-2xl lg:text-left">
          <span className="font-bold">Raih Penghasilan</span> Hingga
        </h3>
        <h2 className="font-bold text-3xl text-white text-center lg:text-4xl lg:text-left">Jutaan Rupiah</h2>
      </div>
    </div>
  );
}
